/* Sidebar */

.sidebar {
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  justify-content: center; 
  gap: 2rem;
  padding: 0 8rem 0 5rem;
  min-height: 60rem;
}

.sidebar > div {
  min-width: 4rem;
  min-height: 4rem;
  border-radius: 5px;
  background-color: var(--input-bg);
  position: relative;
}

.sidebar button {
  color: #cccccc;
  height: 100%;
  width: 100%;
  background-color: var(--input-bg);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar button:hover {
  filter: brightness(0.85);
}

div.layout-div {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem 0.5rem;
  position: relative;
}

.layout-current-indicator {
  width: 1.2rem;
  height: 2rem;
  background-color: var(--input-bg);
  position: absolute;
  left: -1.1rem;
  top: 0.25rem;
  border-radius: 4px;
}

.layout-div > .layout-btn-div {
  height: 2.5rem;
  display: flex;
  gap: 0.2rem;
}

.layout-btn-div {
  display: flex;
  position: relative;
}

.layout-btn {
  color: #cccccc;
  height: 100%;
  width: 100%;
  background-color: var(--input-bg);
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.sidebar svg {
  height: 1.5rem;
  width: 1.5rem;
}

button.layout-opt-btn {
  height: 2.5rem;
  border-radius: 3px;
  color: #cccccc;
  background-color: var(--input-bg);
  display: flex;
  justify-content: center;
}

.dual-icon-1 {
  position: relative;
  left: 0.3rem;
}

.dual-icon-2 {
  position: relative;
  right: 0.3rem;
}

.fill-btn-div.del button {
  color: var(--color-accent);
}

/* ------------------- dropdown ----------------- */

.layout-dropdown {
  position: absolute;
  left: 108%;
  top: 87%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.3rem;
}

.layout-dropdown > div {
  height: 2rem;
}

.layout-dropdown button {
  height: 2rem;
  display: flex;
  justify-content: flex-start;
  width: 7.5rem;
  padding-left: 0.5rem;
  gap: 0.5rem;
  font-size: 0.9rem;
  border-radius: 3px;
}

.layout-dropdown svg {
  height: 1rem;
  width: 1rem;
}


/* ------------------- Sidebar Edit ----------------- */

.sidbar-grab svg,
.layout-del-btn svg {
  height: 1rem;
  width: 1rem;
}

.sidbar-grab:hover {
  cursor: grab;
}

.layout-del-btn svg {
  color: var(--color-accent);
}

.edit button {
  min-width: 2.5rem;
}

.edit .layout-opt-btn {
  display: none;
}

.layout-btn.stop {
  display: flex;
  gap: 0.2rem;
  font-size: 0.8rem;
}

/* ------------------- Sidebar Tooltips ----------------- */

.sidebar-tooltip,
.layout-tooltip {
  position: absolute;
  margin-left: 1.2rem;
  background-color: var(--input-bg);
  border-radius: 2px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  left: 100%;
  width: fit-content;
  height: auto;
  font-size: 0.9rem;
  padding: 0.4rem 0.8rem;

  opacity: 0;
  transition: opacity 0.4s;
  visibility: hidden;
}

.sidebar-tooltip {
  top: 0;
}

.sidebar-tooltip::before,
.layout-tooltip::before {
  content: "";
  position: absolute;
  border: 0.5rem solid var(--input-bg);
  margin-left: -1.3rem;
  border-color: transparent var(--input-bg) transparent transparent;
  visibility: hidden;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.layout-btn-div:hover > .layout-tooltip,
.sidebar > div:hover > .sidebar-tooltip {
  visibility: visible;
  opacity: 1;
}


.layout-btn-div:hover > .layout-tooltip::before,
.sidebar > div:hover > .sidebar-tooltip::before {
  visibility: visible;
  opacity: 1;
}