.main-container {
  height: 100%;
  display: flex;
  gap: 2rem;
  overflow: hidden;
}

/* Form styling */

.form-container {
  flex: 1 1;
  padding: 8rem 10rem;
  /* padding-left: 0rem; */
  overflow-y: scroll;
  scrollbar-color: var(--color-accent) var(--input-bg);
  height: 100%;
}

.dual-mode .form-container {
  padding-right: 5rem;
  padding-left: 0rem;
}

.form-container h3 {
  margin-left: 2rem;
}

.form {
  border: 1px groove var(--input-bg);
  border-radius: 10px;
  margin-top: 1rem;
  min-height: 90%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 1100px;
}

.form::after {
  content: "";
  background: linear-gradient(
    transparent,
    var(--color-accent),
    /* mediumslateblue, */
    transparent
  );
  height: 150px;
  width: 1px;
  position: absolute;
  left: -2px;
  top: 75%;
  opacity: 0;
  transition: top 800ms ease,
              opacity 800ms ease;
}

.form:hover::after {
  top: 0;
  opacity: 1;
}

.form-title-div {
  display: flex;
  justify-content: space-between;
  max-width: 1100px;
}

.next-btn-div {
  display: flex;
  padding: 0 12px;
  gap: 10px;
}

.next-btn-div button {
  border-radius: 3px;
  width: 6rem;
  height: 2rem;
  font-size: 0.9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
}

.next-btn-div svg {
  height: 0.7rem;
  position: relative;
  top: 0.05rem;
}

.next-btn {
  padding-left: 1rem;
}

.prev-btn {
  padding-right: 1rem;
}

.personal-input-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 35%;
}

.form label {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form input,
.form textarea {
  background-color: var(--input-bg);
  color: var(--color-text);
  font-size: 0.9rem;
  padding: 12px;
  outline: none;
}

.form input[type="text"],
.form input[type="email"] {
  height: 3rem;
  appearance: none;
  border: none;
  border-radius: 5px;
}

label > span > span {
  font-weight: 200;
  font-size: 0.8rem;
}

.summary-label {
  flex: 1 1 auto;
  margin-top: 1.5rem;
}

.summary-label textarea {
  height: 85%;
  appearance: none;
  border: none;
  border-radius: 10px;
}

/* Resume viewer */
.resume-container {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
  padding: 5rem 5rem 5rem 0;
  overflow: auto;
  min-height: 1.5vh;
}


.download-btn {
  width: 7rem;
  height: 2rem;
  border-radius: 3px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
  color: var(--color-bg);
}

.resume-nav-div, .resume-top-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  left: -0.20rem;
  width: 58%;
}

.resume-nav-btn {
  display: flex;
  height: 2rem;
  width: 6rem;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  gap: 0.2rem;
}

#resume-viewer {
  flex-basis: 90%;
}

.resume-top-nav .resume-nav-btn {
  width: 3rem;
}